import customImageLoader from "../../utils/imageLoader";
import ImageWithFallback from "./ImageWithFallback";

// TODO: Set up https://github.com/h2non/imaginary implementation to reduce image optimasation costs

function customCloudinaryLoader({ src, width, quality }) {
  // Brute force way to update generic cloudinary URL with specific one for width & quality
  const params = [
    "f_auto",
    "c_limit",
    "w_" + width,
    "q_" + (quality || "auto"),
  ];
  const paramsString = params.join(",");

  return src.replace("f_auto,q_auto", paramsString);
}

// Taken from https://github.com/vercel/next.js/blob/canary/examples/image-component/pages/shimmer.js
const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#E8E8E8" offset="20%" />
      <stop stop-color="#F3F3F3" offset="50%" />
      <stop stop-color="#E8E8E8" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#E8E8E8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

const CustomImage = (props) => {
  const { width, height, src, noPlaceholder, ...rest } = props;

  const imageProps = {};
  if (noPlaceholder) {
    imageProps.placeholder = "empty";
  }

  if (typeof src === "string" && width) {
    if (!noPlaceholder) {
      imageProps.placeholder = "blur";
      imageProps.blurDataURL = `data:image/svg+xml;base64,${toBase64(
        shimmer(width, height)
      )}`;
    }

    if (src?.includes("res.cloudinary")) {
      imageProps.loader = customCloudinaryLoader;
    }
    if (src?.includes("cdn.geerly.com")) {
      imageProps.loader = customImageLoader;
    }
  }
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <ImageWithFallback
      src={src}
      width={width}
      height={height}
      {...imageProps}
      {...rest}
    />
  );
};

export default CustomImage;

/* 
Bettter SVG but not properly animating
<svg width="400" height="400" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <rect id="r" width="400" height="400" fill="#000000" opacity="0.11" />
  <animate  xlink:href="#r"  attributeName="opacity" values="1;0.4;1" dur="1.5s" repeatCount="indefinite"  />
</svg>
*/
