const customImageLoader = ({ src, width, quality }) => {
  if (!src.includes("cdn.geerly.com")) {
    //  throw new Error(`Invalid image source: ${src}`);
  }
  if (quality) {
    return `${src}?format=auto&quality=${quality}&width=${width}`;
  } else return `${src}?format=auto&width=${width}`;
};

export default customImageLoader;
