import React from "react";
import dynamic from "next/dynamic";
import { makeStyles } from "tss-react/mui";

import ClientOnly from "components/ClientOnly/ClientOnly";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import SexesDropdown from "components/PriceComparison/Dropdowns/SexesDropdown.js";
import SizeTabs from "components/PriceComparison/Dropdowns/SizeTabs.js";

import useTranslation from "next-translate/useTranslation";
import SelectStyledButton from "components/Select/SelectStyledButton.js";

import {
  selectFormControl,
  zeroPadding,
} from "../../assets/jss/nextjs-material-kit-pro";

const AutocompleteDropdown = dynamic(() =>
  import("components/PriceComparison/Dropdowns/AutocompleteDropdown.js")
);

const useStyles = makeStyles()({ selectFormControl, zeroPadding });

export default function SizeAndSexDropdowns({
  handleChoice,
  state,
  sexDeals,
  sizeLocaleOptions,
  enableAllOptions,
  loading,
  supportedChoiceTypes,
  showRegardlessOfSupportedChoice,
  sizeSelectRef,
  showFiltersButton,
  handleDrawerToggle,
  showBrandDropdown,
  shoeBrands,
  brandExplainerText,
  brandHelperText,
  gridSizes = {
    xxs: 6,
  },
  disableContainer,
  ...rest
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const color = !state?.size ? "secondary" : "primary";
  const highlight = !state?.size;

  if (showFiltersButton) {
    gridSizes.xs = 4;
  }

  const disabled =
    !supportedChoiceTypes?.includes("sex") && !showRegardlessOfSupportedChoice;

  return (
    <>
      <GridContainer disable={disableContainer} id="sizeSelectContainer">
        {showFiltersButton && (
          <GridItem xs={4} sm={4}>
            <SelectStyledButton
              openLabel={t("nav:filters", { count: 0 })}
              closeLabel={t("nav:hideFilters")}
              loading={loading}
              handleDrawerToggle={handleDrawerToggle}
            />
          </GridItem>
        )}
        {state.sex &&
          (showRegardlessOfSupportedChoice ||
            supportedChoiceTypes?.includes("sex")) && (
            <GridItem className={classes.selectFormControl} {...gridSizes}>
              <SexesDropdown
                label={t("feeds:sex")}
                handleChoice={handleChoice}
                currentSelection={state.sex || ""}
                loading={loading}
                highlight={highlight}
                disabled={disabled}
                renderValue={disabled ? () => {} : null}
                {...rest}
              />
            </GridItem>
          )}
        {(showRegardlessOfSupportedChoice ||
          supportedChoiceTypes?.includes("shoe-size")) && (
          <GridItem {...gridSizes} className={classes.selectFormControl}>
            <SizeTabs
              sizeSelectRef={sizeSelectRef}
              options={state.sizeCharts?.[state.sex]}
              currentSelection={state.size || []}
              sizeLocaleOptions={sizeLocaleOptions}
              handleChoice={!loading && handleChoice}
              sexDeals={!loading && sexDeals}
              enableAllOptions={!loading && enableAllOptions}
              loading={loading}
              state={state}
              color={color}
              renderValue={disabled ? () => {} : null}
              disabled={disabled}
              highlight={highlight}
              {...rest}
            />
          </GridItem>
        )}
      </GridContainer>
      {showBrandDropdown &&
        state.sex &&
        (showRegardlessOfSupportedChoice ||
          supportedChoiceTypes?.includes("sex")) && (
          <>
            {brandExplainerText || ""}
            <GridItem className={classes.zeroPadding} {...gridSizes}>
              <AutocompleteDropdown
                fullWidth
                label={t("feeds:brand")}
                handleChoice={(_e, v, _r, type) => {
                  handleChoice(v, type);
                }}
                currentSelection={state.brand || ""}
                loading={loading}
                disabled={disabled}
                options={shoeBrands}
                type={"Brand"}
                isOptionEqualToValue={(option, value) =>
                  option.databaseId == value?.databaseId || null
                }
                {...rest}
              />
              {brandHelperText || ""}
            </GridItem>
          </>
        )}
    </>
  );
}
